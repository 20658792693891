@tailwind base;
@tailwind components;
@tailwind utilities;



.animation{
    animation: fadeInAnimation ease 2s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}  
@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
     }
}

.projectImg {
    width: 700px;
    height: auto;
}

*{
    font-family: 'IBM Plex Serif', serif;
}